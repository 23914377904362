<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="true" />

        <section class="box">
            <b-tabs v-model="activeTab" @input="getTabData">
                <b-tab-item
                    :key="tabs.project.index"
                    :label="`${tabs.project.label}`"
                    :icon="tabs.project.icon"
                    :value="tabs.project.type"
                >
                    <ProjectTable :projects="projects" :groups="groups" @loadAsyncData="loadAsyncProjects" />
                </b-tab-item>
                <b-tab-item
                    :key="tabs.site.index"
                    :label="`${tabs.site.label}`"
                    :icon="tabs.site.icon"
                    :value="tabs.site.type"
                >
                    <SiteTable :sites="sites" :groups="groups" @loadAsyncData="loadAsyncSites" />
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
</template>

<script>
    import ProjectTable from '../../components/Admin/PreorderStatistic/ProjectTable'
    import SiteTable from '../../components/Admin/PreorderStatistic/SiteTable'
    import { mapActions } from 'vuex'

    export default {
        title: 'Statistiques Précommandes',
        name: 'PreorderStatistic',
        components: { ProjectTable, SiteTable },
        data() {
            return {
                isLoading: false,
                tabs: {
                    project: {
                        index: 1,
                        type: 'project',
                        label: 'Par projet',
                        icon: 'fa fa-list',
                        content: 'test'
                    },
                    site: {
                        index: 2,
                        type: 'site',
                        label: 'Sites',
                        icon: 'fa fa-list',
                        content: 'test'
                    }
                },
                activeTab: 0,

                preorders: [],
                projects: [],
                sites: [],
                groups: [],
                perPage: 25,
            }
        },
        created() {
            this.getTabData(this.activeTab)
        },
        methods: {
            ...mapActions('statistics_preorders', [
                'getPreorders',
                'getPreordersByCampaigns',
                'getPreordersBySites',
                'loadAsyncData'
            ]),
            getTabData(index = this.activeTab) {
                switch (index) {
                    case 0:
                        this.isLoading = true

                        this.getPreordersByCampaigns({ perPage: this.perPage })
                            .then(response => {
                                this.projects = response.projects
                                this.groups = response.groups
                            })
                            .catch(error => {
                                this.$store.dispatch('toastError', error)
                                console.error(error)
                            })
                            .finally(() => (this.isLoading = false))

                        break
                    case 1:
                        this.isLoading = true

                        this.getPreordersBySites({ perPage: this.perPage })
                            .then(response => {
                                this.sites = response.sites
                                this.groups = response.groups
                            })
                            .catch(error => {
                                this.$store.dispatch('toastError', error)
                                console.error(error)
                            })
                            .finally(() => (this.isLoading = false))

                        break
                }
            },
            loadAsyncProjects(data) {
                this.isLoading = true

                data.type = 'campaigns'
                this.loadAsyncData(data)
                    .then(response => {
                        this.projects = response.projects
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                        console.error(error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            loadAsyncSites(data) {
                this.isLoading = true

                data.type = 'sites'
                this.loadAsyncData(data)
                    .then(response => {
                        this.sites = response.sites
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                        console.error(error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            computePercentage(value, total) {
                if (value > 0) {
                    return Math.round((value / total) * 100)
                } else {
                    return 0
                }
            }
        }
    }
</script>
